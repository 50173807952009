import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "extended-essay",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#extended-essay",
        "aria-label": "extended essay permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Extended Essay`}</h3>
    <p>{`The Extended Essay (EE), is a 4,000-word piece of independent research, completed over the course of the 2-year IB Diploma Programme, which allows students to inquire into a topic of special interest. The purpose of the EE is primarily to teach students the research, time management and writing skills they will need to thrive in University, where they will be asked to produce academic papers on a regular basis. In speaking with PSI alumni, many have spoken about the value of the EE and the role that it played in preparing them for University, in some cases indicating that it was the single most useful thing they did to prepare for life after high school.`}</p>
    <p>{`The students of the class of 2021 started the Extended Essay process in November of their Grade 11 year, and submitted the final draft of the essays in December of the following academic year. In order to help students succeed, the essay was broken down into a number of smaller components, each with its own internal deadline, and carefully scaffolded over the course of the 12 months. The students in the class of 2021 chose topics in a number of areas, ranging from geography to mathematics to visual arts. They looked at questions as varied as: the ethical integration of Artificial Intelligence into business; Tesla’s impact on the petrol car market; the role of NGOs in Ukraine’s response to opioid distribution; the effectiveness of Mikhail Gorbachev’s alcohol policies in the former Soviet Union; and the use of mathematical models to predict and map the spread of disease during an epidemic. 23 of our 25 graduating students chose to pursue the full IB Diploma and completed an Extended Essay. Of those students, over half are predicted to receive grades of A or B on the essay.`}</p>
    <p>{`The students of the class of 2022 have been working on their EEs since December, 2020, and will be submitting them by November of 2021. Most students in that group have already completed the majority of their research.`}</p>
    <h3 {...{
      "id": "eca-programme-expansion-story",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#eca-programme-expansion-story",
        "aria-label": "eca programme expansion story permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ECA Programme Expansion Story`}</h3>
    <p>{`The PSI `}<strong parentName="p">{`Extracurricular Activities (ECA) Programme`}</strong>{` is a complex programme that unites various kinds of after school classes. It is dedicated to providing PSI students with exciting new opportunities and high quality experiences.`}</p>
    <p>{`The `}<strong parentName="p">{`ECA programme`}</strong>{` plays an essential role in our students’ life - social, emotional and educational. It is a great way to help children develop their individual personality, cut down on emotional stress, and enhance social and academic skills.`}</p>
    <p><strong parentName="p">{`The Virtual ECA Programme`}</strong>{` - This year the Virtual ECA Programme continued to run online, scheduled from Monday to Friday, facilitated on the Zoom platform with 2 time options:`}</p>
    <ul>
      <li parentName="ul">{`from 15:20 to 16:00 for students from Yellow or Blue cohort depending on the day of the week`}</li>
      <li parentName="ul">{`from 17:00 to 17:40 for all students`}<br parentName="li"></br>
        {`Virtual ECAs had no limitation to the number of participants and were open for joining throughout the whole period of the Season’s programme.`}</li>
    </ul>
    <p>{`43 different activities were offered during 3 ECA Seasons. The programme was enriched with completely new activities, such as:`}</p>
    <ul>
      <li parentName="ul">{`Kids Medical School`}</li>
      <li parentName="ul">{`Smartphone Photography Tips and Tricks`}</li>
      <li parentName="ul">{`Library Ambassadors`}</li>
      <li parentName="ul">{`Cityscape Creators`}</li>
      <li parentName="ul">{`Virtual Travel`}</li>
      <li parentName="ul">{`Quiz Club`}</li>
      <li parentName="ul">{`Comedy Club`}</li>
    </ul>
    <p>{`The successful Virtual ECA Programme showed students’ high interest and strong attendance rates.`}</p>
    <p><strong parentName="p">{`“Happiness, Energy, Creativity”`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      